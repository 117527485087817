import React from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { getAnimation } from "@utils";

const RibbonsOne = ({ fitHeight, color, className: _className }) => {
  const draw = getAnimation("draw");

  return (
    <span
      className={classNames(
        "pointer-events-none flex items-center justify-center",
        {
          "icon--fit-height": fitHeight,
          "text-midnight": color === "midnight",
          "text-carnation": color === "carnation",
          "text-mint": color === "mint",
          "text-white": color === "white",
        },
        _className
      )}
    >
      <m.svg
        id="uuid-d37de58c-bef1-4261-a960-6c57b04b2c5f"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2560 444"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="stroke-[30px] md:stroke-[22px]"
      >
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M-3.7,427.5c418.4-29.1,544.6-105.7,877.2-168.1C1658,112.1,2035.8,364.7,2565.8,130.9"
        />
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M-3.7,302.6c175.5,1.5,473.2,12.5,983.4-112.4c716.4-175.4,901.5,145.9,1586.6-103"
        />
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M-3.7,362.1c174-8,473.2-10.2,985.7-164.4C1690.3-15.2,1842.6,312,2566.3,14.5"
        />
      </m.svg>
    </span>
  );
};

RibbonsOne.defaultProps = {};

export default RibbonsOne;
