import React from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { getAnimation } from "@utils";

const RibbonsTwo = ({ fitHeight, color, className: _className }) => {
  const draw = getAnimation("draw");

  return (
    <span
      className={classNames(
        "pointer-events-none flex items-center justify-center",
        {
          "icon--fit-height": fitHeight,
          "text-midnight": color === "midnight",
          "text-carnation": color === "carnation",
          "text-mint": color === "mint",
          "text-white": color === "white",
        },
        _className
      )}
    >
      <m.svg
        id="uuid-2f968de7-1e7c-472e-bda9-8ad25b190b1e"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2560 524"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="stroke-[30px] md:stroke-[22px]"
      >
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M-15.3,416.8c632.7-76,642.7,147,1274.1,45.5c387.6-62.4,636.4-246.5,1304.5-341.3 M2563.3,15.1
          c-552.5,120.4-880.4,342-1405.1,378.6C762,421.2,521.4,301.6-18.7,380"
        />
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M-7.7,509.9c280.2-117.3,758.9-94.6,971.4-76c586.9,51.4,730.7-145.9,1536.5-190.1l63-3.2"
        />
      </m.svg>
    </span>
  );
};

RibbonsTwo.defaultProps = {};

export default RibbonsTwo;
