import React from "react";
import classNames from "classnames";
import RibbonsOne from "@svg/RibbonsOne";
import RibbonsTwo from "@svg/RibbonsTwo";
import RibbonsThree from "@svg/RibbonsThree";
import RibbonsFour from "@svg/RibbonsFour";

const Ribbons = ({ variant, color }) => {
  switch (variant) {
    case "one":
      return <RibbonsOne color={color} />;
    case "two":
      return <RibbonsTwo color={color} />;
    case "three":
      return <RibbonsThree color={color} />;
    case "four":
      return <RibbonsFour color={color} />;
    default:
      return <RibbonsOne color={color} />;
  }
};

const RibbonContainer = ({
  fitHeight,
  color,
  bgColor,
  splitColor,
  className: _className,
  variant,
}) => {
  return (
    <section
      className={classNames(
        "relative z-10",
        {
          "icon--fit-height": fitHeight,
          "bg-midnight": bgColor === "midnight",
          "bg-carnation": bgColor === "carnation",
          "bg-mint": bgColor === "mint",
          "bg-white": bgColor === "white",
        },
        _className
      )}
    >
      <div className="relative z-10">
        <Ribbons variant={variant} color={color} />
      </div>
      {splitColor !== "none" && (
        <div
          className={classNames("absolute inset-x-0 bottom-0 h-1/2", {
            "bg-midnight": splitColor === "midnight",
            "bg-carnation": splitColor === "carnation",
            "bg-mint": splitColor === "mint",
            "bg-white": splitColor === "white",
          })}
        />
      )}
    </section>
  );
};

RibbonContainer.defaultProps = {
  color: "mint",
  bgColor: "midnight",
};

export default RibbonContainer;
