import React from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { getAnimation } from "@utils";

const RibbonsFour = ({ fitHeight, color, className: _className }) => {
  const draw = getAnimation("draw");

  return (
    <span
      className={classNames(
        "pointer-events-none flex items-center justify-center",
        {
          "icon--fit-height": fitHeight,
          "text-midnight": color === "midnight",
          "text-carnation": color === "carnation",
          "text-mint": color === "mint",
          "text-white": color === "white",
        },
        _className
      )}
    >
      <m.svg
        id="uuid-b1f40c9d-43a7-488d-9008-d8a4d8969ef8"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2560 398"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="stroke-[30px] md:stroke-[22px]"
      >
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M35.3,27.7c576.6,145.4,921.4,188.4,1192.5,174c58.1-3.1,164.7-12.5,238.2-26.4c68.5-12.9,154.9-25,227-28.7
	l0,0c144.3-10.6,288.6,21.4,414.7,92.2l78.1,43.8L2214,296l32.2,10.2l29.1,6.3l29,3.4h23.5c51.8,0,146-33.6,240.2-86.4"
        />
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M-5,31c68.2,43.9,278.8,107.8,575.9,75.1c369.2-40.6,572.6,34.3,742.8,75c96.6,23.1,191.6,15.9,279.4,15.9l0,0
	c146,0,290.4,30.4,423.9,89.3l109.7,48.4l3.5,1.4c63.7,24.6,130.9,39.5,199,44.2l0,0c36.5,2.5,132.9-2.3,238.7-39.3"
        />
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M-5,15.3C112.8,57.1,489.2,109,683.6,74.8c272.8-48.1,506.3,17.3,630.8,54.5c30.1,9.9,132.6,30.4,176.5,31.1
	c54.9,0.8,102.1-3.2,145.3-1.8l107.2,7.8c95.7,6.9,189.4,31.5,276.2,72.1l169.9,79.7l4,1.7c51.2,21.2,106.1,32.1,161.6,32.1l0,0
	c66.5-3.7,143.8-23.1,212.7-63"
        />
      </m.svg>
    </span>
  );
};

RibbonsFour.defaultProps = {};

export default RibbonsFour;
