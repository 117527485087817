import React from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { getAnimation } from "@utils";

const RibbonsThree = ({ fitHeight, color, className: _className }) => {
  const draw = getAnimation("draw");

  return (
    <span
      className={classNames(
        "pointer-events-none flex items-center justify-center",
        {
          "icon--fit-height": fitHeight,
          "text-midnight": color === "midnight",
          "text-carnation": color === "carnation",
          "text-mint": color === "mint",
          "text-white": color === "white",
        },
        _className
      )}
    >
      <m.svg
        id="uuid-79ccef58-1420-4ea6-a805-81bfc3f745d8"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2560 408"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="stroke-[30px] md:stroke-[22px]"
      >
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M-4.9,140.2l54.3,15.5C804.9,357.6,1088.5,62.4,1587,264.3c331.9,134.4,616.5,110,910.6,32.9l69.4-19.2"
        />
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M2566.9,163c-315,147.6-377.8,230.3-1109.4,0C907-10.3,528.3,221.8,64.8,98L-4.9,80 M-4.9,17.9l85.6,20.7
          c437.7,117,869.7-160.4,1457.6,82.8c382.8,158.3,539.6,335.6,1028.8,243.4"
        />
      </m.svg>
    </span>
  );
};

RibbonsThree.defaultProps = {};

export default RibbonsThree;
